.contatos {
    width: 23.33vw;
    color: var(--white)
}

.contato {
    display: flex;
    align-items: center;
    margin-top: 2em;
}

.contato img {
    margin-right: 2em;
}

.img-align {
    margin-right: 1em;
}

.contato .endereco {
    cursor: pointer;
}

.telefone {
    display: flex;
    flex-direction: column;
}

.endereco {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1000px) {
    .contatos {
        padding: 24px 16px;
        width: unset;
        color: var(--black);
        background-color: var(--grayBackground);
    }
}