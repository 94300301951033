.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    padding: 56px 6em;
    text-align: center;
}

.containerCard {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.card {
    width: 400px !important;
    height: 400px !important;
}

.cardContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
}

.cardTitle {
    color: var(--purple);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; 
    letter-spacing: -2px;
}

.price {
    color: var(--blue);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; 
}

.certificadoButton {
    max-width: fit-content;
    min-width: 128px;
    height: 40px;
    padding: 8px;
    border: none;
    border-radius: 1em;
    font-size: 14px;
    font-weight: 700;
    backdrop-filter: blur(10px);
    gap: 1em;
    color: var(--white);
    background-color: var(--blue);
}

@media (max-width: 1000px) {
    .container {
        padding: 24px 16px;
    }

    .card {
        width: unset !important;
        height: unset !important;
    }
}

@media (min-width: 1920px) {
    .container {
        width: 1408px;
        margin: 0 auto;
        padding-inline: 0;
    }
}
