.container {
    overflow-x: hidden;
}

.labelChat {
    position: fixed; 
    right: 90px;
    bottom: 28px;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: var(--floatShadow);
    font-size: 16px;  
    color: var(--darkGray);
    z-index: 2147483645;
}