.menuItemsMobile {
    padding-top: 66px;
}

.menuActive {
    color: var(--green)!important;
}

.menuItemsMobile a {
    padding: 16px 0;
    color: var(--darkGray);
}

.menuItemsMobile .menuItem {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}