.sitemap {
    width: 23.33vw;
    color: var(--white);
}

.links {
    display: flex;
    margin-top: 2em;
    flex-direction: column;
}

.links a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
}