.menuMobileContainer {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    width: 100%;
    max-height: 100%;
    padding: 12px 16px;
    background: var(--neutralWhite);
    z-index: 11;
}

.menuMobileContainer .flexlogoMenu {
    width: 100px;
}

.flexlogoMenu img {
    width: 100%;
}

.menuMobileIcon {
    cursor: pointer;
    color: var(--darkGray);
}

.menuItemsContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 0 16px;
    background: var(--neutralWhite);
    z-index: 10;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
}

.closeMenuIcon {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
}

.openMenuMobile {
    transform: translateX(0%);
}

.closeMenuMobile {
    transform: translateX(100%);
}