.container {
    background-color: var(--grayBackground);
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 56px 6em;
}

.title {
    color: #242A30;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 122%;
    letter-spacing: -1.147px;
}

.benefits {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1395px;
    padding-block: 32px;
    gap: 24px;
}

.benefit p {
    text-align: center;
}

.benefit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px;
    width: 260px;
    height: 260px;
    border-radius: 16px;
    border: 1px solid var(--secondaryGray);
}

.benefitCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    padding-block: 8px;
}

.adquiraCertificadoButton {
    align-self: center;
    max-width: fit-content;
    min-width: 254px;
    padding: 16px;
    border: none;
    border-radius: 1em;
    font-size: 16px;
    font-weight: 700;
    backdrop-filter: blur(10px);
    gap: 1em;
    color: var(--white);
    background-color: var(--purple);
}

@media (max-width: 1000px) {
    .container {
        background-color: var(--white);
    }

    .content {
        padding: 0;
        padding-bottom: 24px;
        align-items: unset;
        justify-content: unset;
    }

    .benefit {
        padding: 24px;
        margin-left: 16px;
        width: unset;
        height: unset;
    }

    .benefitCard {
        min-width: 148px;
        max-width: 148px;
        width: 148px;
    }

    .title {
        text-align: left;
        padding: 24px 16px 0 16px;
    }

    .benefits {
        max-width: unset;
    }
}

@media screen and (min-width: 1920px) {
    .content {
        width: 1408px;
        margin: 0 auto;
        padding-inline: 0;
    }
}