.icon{
    display: flex;
}
.circle{
    padding: 7px;
    border-radius: 100%;
    cursor: pointer;
}
.circle svg {
    width: 17px;
    height: auto;
}