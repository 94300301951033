.container {
    background-color: var(--darkPurple);
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 2em;
    padding: 48px 6em 0 6em;
    margin-top: 86px !important;
    width: 100%;
    position: relative;
}

.text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    z-index: 1;
    padding: 16px;
    max-width: 440px;
    margin-left: -470px;
    color: var(--white) !important;
}

.img img {
    max-height: 665px;
}

.shadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 181px;
    background: linear-gradient(0deg, #593C89 18.79%, rgba(86, 56, 131, 0.00) 93.93%);
}

.title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    font-weight: 600;
}

.title h1 {
    color: var(--white) !important;
}

.description {
    line-height: 160%;
}

.certificadoButton {
    max-width: fit-content;
    min-width: 254px;
    padding: 16px;
    border: none;
    border-radius: 1em;
    font-size: 16px;
    font-weight: 700;
    backdrop-filter: blur(10px);
    gap: 1em;
    color: var(--white);
    background-color: var(--blue);
}

@media (max-width: 1000px) {
    .content {
        flex-direction: column;
        padding: 16px;
        margin-top: 58px !important;
        min-height: 568px;
    }

    .text {
        align-items: center;
        margin-left: unset;
        text-align: center;
    }

    .title {
        align-items: center;
        text-align: center;
    }

    .img {
        margin-left: 220px;
        margin-top: -110px;
    }

    .img img {
        max-height: 455px;;
    }
}

@media (min-width: 1920px) {
    .content {
        width: 1408px;
        margin: 0 auto;
        padding-inline: 0;
    }
}