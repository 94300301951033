.menu {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 100vw;
    gap: 2em;
    filter: drop-shadow(4px 4px 6px #00000033);
    border: 1px solid var(--lightGray);
    border-radius: 0px 0px 16px 16px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 10;
    background: #ffffffb8;
}

.menuDark,
.menuDark .submenus {
    filter: drop-shadow(4px 4px 6px #00000033);
    border: 1px solid #303030;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: linear-gradient(96.75deg, #cdcdcd 0%, #000000bf 100%);
}

.menuDark {
    background: linear-gradient(96.75deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
}

.menuDark .submenus {
    background: linear-gradient(96.75deg, #cdcdcddb 0%, #000000ba 100%);
}
.menuDark .menuItemButton, .menuDark .linkSubmenu {
    color: white;
}

.flexLogo {
    padding: 1em;
}