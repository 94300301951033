@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

:root {
    --menuShadow: linear-gradient(96.75deg, #ffffff99 0%, #ffffff00 100%);
    --shadow: 4px 4px 6px #00000033;
    --floatShadow: 3px 6px 19.3px 0px #00000014;
    --wholeShadow: 0 0 10px 0px #00000014;
    --green: #00D600;
    --lightGreen: #00d60066;
    --darkGreen: #009600;
    --white: #ffffff;
    --neutralWhite: #F9F9F9;
    --black: #000000;
    --gray: #00000066;
    --lightGray: #ffffff4d;
    --red: #ff000099;
    --lightRed: #ff000066;
    --fontFamily: 'Poppins', sans-serif;
    --secondaryGray: #6B7388;
    --orange: #E07A02;
    --darkOrange: #D26900;
    --darkGray: #242A30;
    --neutralColorMedium: #CFE0EC;
    --neutralColorLight: #f2f8fd;
    --neutralColorPure: #242627;
    --ashGray: #F4F4F4;
    --grayBackground: #F8FAF9;
    --darkBackground: #1A1B23;
    --blue: #43A0C8;
    --lightBlue: #5891CA;
    --purple: #7846CD;
    --darkPurple: #593C8A;

    --label-font-size: 14px;
    --caption-font-size: 12px;
}

a {
    text-decoration: none;
}

.greenTitle {
    color: var(--green);
}

.title h1 {
    font-size: 52px;
    font-weight: 600;
    color: var(--darkGray);
    line-height: 56px;
}

.title h2 {
    font-size: 52px;
    font-weight: 600;
    color: var(--darkGray);
}

.topBanner {
    margin-top: 10vh;
    max-width: 100%;
    height: auto;
}

.topBannerMobile {
    margin-top: 58px;
    max-width: 100%;
    height: auto;
}

.topBannerLanding {
    margin-top: 0 !important;
    max-width: 100%;
    height: auto;
}

@media (max-width: 1200px) {
    .title h1 {
        font-size: 40px;
    }
}

@media (max-width: 1000px) {
    .title h1, h2 {
        font-size: 28px !important;
        color: var(--neutralColorPure);
    }
}

@media screen and (min-width: 1920px) {
    .topBannerContainer {
        display: flex;
        justify-content: center
    }

    .topBanner {
        justify-content: center;
    }
}