.dropdown {
    display: flex;
    padding-top: 1vh;
}

.submenus {
    display: none;
    position: absolute;
    width: auto;
    min-width: 11em;
    list-style: none;
    background: #ffffffe9;
    border-radius: 16px;
    backdrop-filter: blur(10px);
    padding: 0.188em 0;
}

.singleSubmenus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    height: 3.6em;
    padding: 1em;
    font-size: 16px;
    line-height: 160%;
}

.linkSubmenu {
    text-decoration: none;
    color: var(--black);
    padding: 1em;
    transition: border 0.8s ease-out 0s;
    border: 1px solid #ffffff00;
    border-radius: 200px;
}

.linkSubmenu:hover {
    border: 1px solid var(--green);
    border-radius: 200px;
}

.linkSubmenu:active {
    color: var(--green);
}

.singleMenuItem:hover .submenus,
.submenus:hover .submenus {
    display: block;
}