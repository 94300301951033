@import './globalVariables.css';

* {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--fontFamily);
}

.grecaptcha-badge {
    width: 256px;
    height: 60px;
    display: block;
    transition: right 0.3s ease 0s;
    position: fixed;
    bottom: 14px;
    right: -186px;
    box-shadow: gray 0px 0px 5px;
    border-radius: 2px;
    overflow: hidden;
    visibility: visible !important;
    z-index: 1;
}

.whatsapp {
    position: fixed;
    bottom: 6px;
    right: 75px;
    z-index: 1;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.whatsapp:hover {
    transform: scale(1.2);
}

@media (max-width: 1000px) {

    h1,
    h2 {
        font-size: 28px !important;
        line-height: 34px !important;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}