.rodapeContainer {
    background-color: var(--darkGray);
    z-index: 2;
}

.rodape {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2em;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    padding: 80px 6em;
    width: 100%;
    min-height: 286px;
    background-color: var(--darkGray);
}

.rodape a {
    color: var(--white);
}

.rodape h2 {
    font-size: 1em;
}

.dark {
    width: 28vw !important;
}

.fundoRodapeDark {
    background-color: #000;
    width: 100%;
    height: 26em;
}

@media (min-width: 1920px) {
    .rodape {
        width: 1408px;
        margin: 0 auto;
        padding-inline: 0;
    }
}