.generalStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    cursor: pointer;
}

.commonButton {
    height: 3.6em;
    padding: 1em;
    border: none;
    border-radius: 1em;
    font-size: 16px;
    font-weight: 700;
    backdrop-filter: blur(10px);
    gap: 1em;
}

.commonSmallButton {
    height: 40px;
    padding: 16px;
    border: none;
    border-radius: 16px;
    box-shadow: var(--shadow);
    font-size: 14px;
    font-weight: 700;
    gap: 0.5em;
}

.floatingButton {
    height: 3.6em;
    padding: 1em;
    border: 1px solid var(--lightGray);
    border-radius: 2em;
    box-shadow: var(--shadow);
    backdrop-filter: blur(10px);
    font-size: 16px;
    font-weight: 700;
    gap: 1em;
}

.smallButton {
    height: 1.6em;
    padding: 4px 8px;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
}

.primary {
    background-color: var(--green);
    color: var(--white);
}

.primary:hover {
    color: var(--green);
    background-color: var(--white);
    font-weight: 700;
    border: 1px solid var(--green);
    transition: 500 ease-in-out;
}

.primary:active {
    background-color: var(--darkGreen);
}

.secondary {
    color: var(--darkGreen);
    background-color: transparent;
    font-weight: 700;
    border: 1px solid var(--green);
    transition: 500 ease-in-out;
}

.secondary:hover {
    transition: 50ms ease-in-out;
    background-color: var(--green);
    color: var(--white);
}

.secondary:active {
    color: var(--white);
    background: var(--darkGreen);
    border: 1px solid var(--darkGreen);
}

.tertiary {
    color: var(--green);
    background-color: transparent;
    font-weight: 400;
    border: none;
    backdrop-filter: none;
    box-shadow: none;
}

.tertiary:hover {
    transition: 500ms ease-in-out;
    font-weight: 700;
}

.tertiary:active {
    color: var(--white);
    background: var(--darkGreen);
}

.quaternary {
    background-color: var(--orange);
    color: var(--white);
}

.quaternary:hover {
    color: var(--orange);
    background-color: var(--white);
    font-weight: 700;
    border: 1px solid var(--orange);
    transition: 500 ease-in-out;
}

.quaternary:active {
    color: var(--white);
    background-color: var(--darkOrange);
}

.disabled {
    color: var(--white);
    background: var(--gray);
    box-shadow: none;
    backdrop-filter: none;
    cursor: default;
}

.cancel {
    color: var(--white);
    background: var(--red);
}

.cancel:hover {
    transition: 500ms ease-in-out;
    color: var(--white);
    background: var(--lightRed);
}

.cancel:active {
    color: var(--white);
    background: var(--gray);
}

.smallPrimary {
    background-color: var(--green);
    color: var(--white)
}

.smallSecondary {
    background-color: var(--white);
    color: var(--gray);
    border: 1px solid var(--gray);
    box-shadow: none;
}

.smallTertiary {
    color: var(--green);
    background-color: transparent;
    font-weight: 700;
    border: none;
    backdrop-filter: none;
    box-shadow: none;
}

.smallQuaternary {
    background-color: var(--orange);
    color: var(--white);
}

.smallCancel {
    color: var(--white);
    background: var(--red);
    backdrop-filter: none;
}

.pulseButton {
    animation-name: pulse;
    animation-duration: 5.0s;
    animation-timing-function: ease-in-out;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@keyframes pulse {
    0% {
        transform: scale(.9);
        -moz-box-shadow: 0 0 0 0 #00d60070;
        box-shadow: 0 0 0 0 #00d60070;
        border-radius: 100%;
    }

    70% {
        transform: scale(1);
        -moz-box-shadow: 0 0 10px 8px #00d60070;
        box-shadow: 0 0 10px 8px #00d60070;
        border-radius: 100%;
    }

    100% {
        transform: scale(.9);
        -moz-box-shadow: 0 0 0 0 #00d60070;
        box-shadow: 0 0 0 0 #00d60070;
        border-radius: 100%;
    }
}

.loadingButton:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 1em;
    border-radius: 60%;
    border: 1px solid #fff;
    border-color: #fff transparent #fff transparent;
    transition: width 0.5s ease-in-out;
    animation: loadingEvent 0.8s linear infinite;
}

@keyframes loadingEvent {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}