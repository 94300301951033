.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    padding: 56px 6em;
    text-align: center;
}

.title {
    color: var(--darkGray);
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 122%;
}

.cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    width: 332px;
    height: 320px;
    padding: 16px;
    border-radius: 4px;
    position: relative;
}

.containerNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-color: var(--white);
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
}

.description {
    color: var(--darkGray);
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
}

.icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
}

.openPassoAPasso {
    align-self: center;
    max-width: fit-content;
    min-width: 254px;
    padding: 16px;
    margin-block: 16px;
    border: none;
    border-radius: 1em;
    font-size: 16px;
    font-weight: 700;
    backdrop-filter: blur(10px);
    gap: 1em;
    color: var(--white);
    background-color: var(--purple);
}

@media (max-width: 1532px) {
    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }
}

@media (max-width: 1000px) {
    .container {
        gap: 24px;
        padding: 16px;
        background-color: var(--grayBackground);
    }

    .cards {
        gap: 4px;
    }

    .card {
        width: 162px;
        height: unset;
    }

    .containerNumber {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }

    .description {
        font-size: 14px;
    }

    .icon {
        right: 10px;
        top: 7px;
        width: 24px;
        height: 24px;
    }
}

@media (min-width: 1920px) {
    .container {
        width: 1408px;
        margin: 0 auto;
        padding-inline: 0;
    }
}
