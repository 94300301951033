.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--white);
    pointer-events: all !important;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    height: 651px;
    padding: 32px 256px;
    text-align: center;
    color: var(--secondaryGray);
    background-color: var(--grayBackground);
}

.title {
    font-size: 56px;
    color: var(--darkGray);
}

.description {
    font-size: 28px;
    margin-bottom: 24px;
}

.link {
    text-decoration: none;
    color: var(--secondaryGray);
    transition: color 0.3s ease;
}

.link:hover {
    color: #555d72;
}

@media (max-width: 1000px) {
    .content {
        padding: 32px 16px;
    }

    .title {
        font-size: 28px;
    }
    
    .description {
        font-size: 16px;
    }
}