.container {
    background-color: var(--grayBackground);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 56px 6em;
}

.perguntas {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 160%;
    color:  var(--darkGray);
}

.pergunta {
    font-weight: 700;
}

.resposta {
    font-weight: 400;
}

.link {
    color: var(--lightBlue);
    text-decoration: underline !important;
}

.link:hover {
    color: var(--blue);
}

@media (max-width: 1000px) {
    .content {
        gap: 16px;
        padding: 16px;
    }

    .perguntas {
        font-size: 14px;
    }
}

@media (min-width: 1920px) {
    .content {
        width: 1408px;
        margin: 0 auto;
        padding-inline: 0;
    }
}