.social {
    width: 23.33vw;
    color: var(--white);
}

.social .socialTitle {
    display: flex;
    flex-direction: column;
}

.social .midiasSociais {
    display: flex;
}

.social .midiaSocial {
    margin: 1em 1.5em 1em 0;
    font-family: 'Social Media Circled';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.social .politicaPrivacidadeTagContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
}

.social .politicaPrivacidadeTag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: fit-content;
    height: 28px;
    padding: 0px 16px;
    border-radius: 28px;
    border: 1px solid var(--black);
    white-space: nowrap;
    font-weight: 500;
}

.social .politicaPrivacidadeTag a {
    color: var(--black);
}

.social .bottomContent {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.social img {
    cursor: pointer;
}

@media (max-width: 1000px) {
    .social {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px 16px;
        width: unset;
        color: var(--black);
    }

    .social img {
        width: 98px;
        height: 53px;
    }
}