.menuItemButton {
    background-color: transparent;
    padding: 1em;
    transition: border 50ms ease-out 200ms;
    border: 1px solid #ffffff00;
    border-radius: 200px;
    font-size: 1em;
    cursor: pointer;
}

.menuItemButton:active {
    color: var(--green);
}

.singleMenuItem {
    height: 10vh;
    line-height: 10vh;
}

.menuItemButton:hover {
    border: 1px solid var(--green);
    border-radius: 200px;
}