.horizontalScrollContainer {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.horizontalScrollContainer .content {
    display: flex;
}

.horizontalScrollContainer::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--neutralColorLight);
    cursor: pointer;
}

.horizontalScrollContainer::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--neutralColorMedium);
    cursor: pointer;
}